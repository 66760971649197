import {
    shareGoods,
    getGoodsDetail
} from '@/utils/goods.js';
import { weichatTools } from '@/utils/weichatShare.js';
import useClipboard from "vue-clipboard3"
export default {
    data() {
        return {
            isShowPage: false,
            checkedS: '',
            checkedArr: [],
            showWxf: false,
            goodsId: '',
            goodsInfo: '',
            shareGoodQRcode: '',
            showHb: false,
            qrImg: '',
            isShareToast: false
        }
    },
    created() {
        this.goodsId = this.$route.query.goodsId;
        this.getGoodsDetailFunc();
    },
    methods: {
        // 获取商品详情
        getGoodsDetailFunc() {
            let data = {
                goodId: this.goodsId,
                memberId: this.$cookies.get('memberId')
            }
            getGoodsDetail(data).then(res => {
                if (res.code == 1) {
                    let aData = JSON.parse(JSON.stringify(res.result.mallEntityGood));

                    // 轮播图
                    let arr = aData.picture ? aData.picture.split(',') : [];
                    this.goodsPic = arr;

                    // 商品详情信息
                    let remarkArr = aData.remarkPics ? aData.remarkPics.split('|') : [];
                    this.remarkArr = remarkArr;

                    this.goodsInfo = aData;
                    setTimeout(() => {
                        this.isShowPage = true;
                        this.shareGoodsFunc();
                    }, 500)
                }
            })
        },
        // 分享
        shareGoodsFunc() {
            let data = {
                goodId: this.goodsId,
                memberId: this.$cookies.get('memberId')
            }
            shareGoods(data).then(res => {
                console.log('分享', res)
                this.shareGoodQRcode = res.data.shareGoodQRcode;
                this.qrImg = res.data.pictureEncode;
                weichatTools.share.shareFunc(this.goodsInfo.name, '灵龙惠生活“理性种草寻好货”', this.shareGoodQRcode, this.qrImg);
            })
        },
        // 复制订单号
        async copyFun() {
            try {
                const { toClipboard } = useClipboard()
                let msg = this.goodsInfo.name + '\n【在售价】' + parseFloat(this.goodsInfo.originalPrice / 100).toFixed(2) + '\n【券后价】' + parseFloat(this.goodsInfo.salePrice / 100).toFixed(2) + '\n【在灵龙惠生活下单】' + parseFloat(this.goodsInfo.salePrice / 100).toFixed(2) + '\n【点击链接即可享受特价福利】' + this.shareGoodQRcode;
                await toClipboard(msg)
                this.showWxf = true;
            } catch (e) {
                console.error(e)
            }
        },
        // 获取微信配置信息
        getWxConfigFunc() {
            this.isShareToast = true;
        }
    },
}