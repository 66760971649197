<template>
  <div class="share" v-if="isShowPage">
    <div class="flex flex-left align-center share-one">
      <img src="../../assets/img/share/notice.png" alt="">如有需要可以复制给予的参考文案，在分享后黏贴给好友
    </div>
    <div class="share-two">
      <p class="share-two-title">{{goodsInfo.name}}</p>
      <div class="share-two-info">
        【在售价】
        <span>{{parseFloat(goodsInfo.originalPrice/100).toFixed(2)}}</span>
      </div>
      <div class="share-two-info">
        【券后价】
        <span>{{parseFloat(goodsInfo.salePrice/100).toFixed(2)}}</span>
      </div>
      <div class="share-two-info">
        【在灵龙惠生活下单】
        <span>{{parseFloat(goodsInfo.salePrice/100).toFixed(2)}}</span>
      </div>
      <div class="share-two-info">
        【点击链接即可享受特价福利】
        <span>{{shareGoodQRcode}}</span>
      </div>
      <!-- <div class="flex flex-center align-center share-two-radio">
        <van-checkbox v-model="checkedS" checked-color="#E62129">文案包含邀请码</van-checkbox>
      </div> -->
      <div class="share-two-btn">
        <van-button color="#E62129" plain type="default" style="height: 100%;" round @click="copyFun()">复制文案</van-button>
      </div>
    </div>
    <!-- <div class="flex flex-left align-center share-three">
      <span></span>
      分享图片
      <span></span>
    </div> -->
    <!-- <van-checkbox-group v-model="checkedArr">
      <ul class="flex flex-between share-four">
        <li class="share-four-li" v-for="item in 3" :key="item.id">
          <div class="share-four-li-img"></div>
          <div class="flex flex-between align-center share-four-li-info">
            <div class="share-four-li-info-l">
              意大利意大利莫斯卡意大利意大利莫斯卡
            </div>
            <div class="share-four-li-info-r">
              ¥<span>234</span>
            </div>
          </div>
          <van-checkbox class="share-four-li-check" name="a" checked-color="#E62129"></van-checkbox>
        </li>
      </ul>
    </van-checkbox-group> -->
    <div class="share-five">
      <div class="flex flex-center align-center share-five-title">
        <span></span>
        分享至
        <span></span>
      </div>
      <div class="flex flex-between share-five-main">
        <div class="flex flex-center flex-wrap share-five-main-list" @click="showHb = true;">
          <img src="../../assets/img/share/sc.png" alt="">
          <p>生成海报</p>
        </div>
        <div class="flex flex-center flex-wrap share-five-main-list" @click="getWxConfigFunc">
          <img src="../../assets/img/share/wx.png" alt="">
          <p>微信</p>
        </div>
        <div class="flex flex-center flex-wrap share-five-main-list" @click="getWxConfigFunc">
          <img src="../../assets/img/share/qq.png" alt="">
          <p>QQ</p>
        </div>
        <div class="flex flex-center flex-wrap share-five-main-list" @click="getWxConfigFunc">
          <img src="../../assets/img/share/wx-f.png" alt="">
          <p>朋友圈</p>
        </div>
        <div class="flex flex-center flex-wrap share-five-main-list" @click="getWxConfigFunc">
          <img src="../../assets/img/share/qq-f.png" alt="">
          <p>QQ空间</p>
        </div>
      </div>
    </div>
    <!-- 去朋友圈分享 -->
    <van-popup v-model:show="showWxf" round>
      <div class="share-wxf">
        <div class="flex flex-center align-center share-wxf-title">
          <span></span>
          将好物分享给身边人
          <span></span>
        </div>
        <div class="flex flex-center align-center share-wxf-msg">
          <img src="../../assets/img/share/share-wxf.png" alt="">
          分享文案已自动复制
        </div>
        <div class="flex flex-between share-wxf-btn">
          <van-button class="share-wxf-btn-list" plain type="default" color="#999999" round @click="showWxf = false;">取消</van-button>
          <van-button class="share-wxf-btn-list" type="default" color="#E62129" round>打开微信</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 海报 -->
    <van-popup v-model:show="showHb" round>
      <div class="qrimg">
        <img :src="qrImg" alt="">
      </div>
    </van-popup>
    <!-- 分享弹层 -->
    <div class="share-fix" v-show="isShareToast">
      <div class="share-fix-line" :style="{background: 'url('+require('../../assets/img/goods/share-icon1.png')+')no-repeat center center',backgroundSize: 'cover'}"></div>
      <div class="flex flex-left share-fix-dec">
        <img src="../../assets/img/goods/share-icon2.png" alt="" class="share-fix-dec-icon">
        <div class="share-fix-dec-content">
          请点击右上角的“...”<br/>
          分享商品给你的身边人
        </div>
      </div>
      <div class="share-fix-btn" :style="{background: 'url('+require('../../assets/img/goods/share-icon3.png')+')no-repeat center center',backgroundSize: 'cover'}" @click="isShareToast = false;">我知道啦</div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
  .qrimg{
    width: 320px;
    height: auto;
    img{
      width: 100%;
      vertical-align: top;
    }
  }
</style>